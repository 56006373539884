.divBackIcon {
	position: fixed;
	left: 10px;
	top: 10px;
	font-size: 15px;
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
	border: 1px solid white;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	-ms-border-radius: 5px;
	-o-border-radius: 5px;
	padding: 1px 5px;
	box-shadow: 0 0 3px white;
	cursor: pointer;
}
.backIcon {
	font-size: 20px;
}
.containerSingleListItemView {
	display: flex;

	padding-top: 10px;
	background-color: #29a6dc;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 95%;
	margin: 0 auto;
}
.formSingleListItemView {
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 10px 20px;
	margin: 0 20px;
	flex: 1;
	gap: 15px;
}
.formSingleListItemView input {
	background-color: transparent;
	border-radius: 5px;
	padding: 5px 15px;
	font-size: 1rem;
	color: black;
	font-weight: bold;
}
.allListItems {
	display: block;
	margin: 10px auto;
	width: 95%;
}
.allListItems span {
	font-size: 1rem;
	text-align: center;
	display: block;
}
.singleItem {
	display: flex;
	background-color: #5bb9e1;
	border: 1px solid #0fa0df;
	border-radius: 10px;
	padding: 10px 20px;
	font-size: 1rem;
	gap: 20px;
	align-items: center;
	margin: 10px 0;
	justify-content: space-between;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	cursor: pointer;
}
.singleItem p {
	flex: 1;
}
.trashSingleItem {
	font-size: 2rem;
	font-weight: bolder;
}

@media screen and (max-width: 800px) {
	.singleItem {
		border: none;
		border-bottom: 1px solid aliceblue;
		padding: 1px 5px;
		margin-top: 2px;
	}
	.formSingleListItemView {
		display: flex;
		flex-direction: column;
	}
	.formSingleListItemView input,
	.formSingleListItemView button {
		background-color: transparent;
	}
	.formSingleListItemView button {
		padding: 5px 35px;
		border-radius: 10px;
		font-size: 1rem;
	}
	.singleItem p,
	.trashSingleItem {
		font-size: 1rem;
	}
	.singleItem p::first-letter {
		text-transform: uppercase;
	}
}
@media screen and (max-width: 800px) and (orientation: portrait) {
	.containerSingleListItemView {
		padding-top: 25px;
	}
}
