.cos {
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  font-size: 14px;
  color: black;
}

.logo {
  display: block;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 5%;
  overflow: hidden;
}

img {
  width: 100%;
}

.login-form h1 {
  margin: 10px auto;
  text-align: center;
  margin-bottom: 10px;
  color: black;
  font-size: 32px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -appearance: textfield;
}

.field input {
  outline: none;
  font-family: Poppins-Regular;
  font-size: 1.5rem;
  font-weight: bolder;
  display: block;
  width: 100%;
  background-color: transparent !important;
  background-image: none !important;
  color: black !important;
  background: transparent;
  padding: 0 5px;
  padding-top: 15px;
  box-shadow: none !important;
  border: none !important;
}

.field input,
.field label {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.container {
  display: block;
  background-color: #29a6dc;
}

.wrap-login {
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
}

.login-form {
  width: 310px;
  margin: 0 auto;
}

.wynik {
  display: none;
}

.wynikOK {
  background-color: lightgreen !important;
}

.visible {
  display: block;
  border: 1px solid white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #da3030;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.line {
  display: block;
  width: 100%;
  border-bottom: 2px solid #adadad;
  margin: 15px auto;
}

.field {
  display: flex;
  flex-flow: column;
  margin-bottom: 0.5em;
  position: relative;
  padding: 6px 0;
  padding-top: 20px;
}

.underline {
  content: "";
  display: block;
  margin: 7px;
  text-align: center;
  position: relative;
  bottom: -54px;
  left: 0;
  height: 2px;
  transition: all 0.5s;
  background: #61aac3;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.bg-red {
  background: #ed553b;
}

.bg-under {
  width: 100%;
  background: #61aac3;
}

.field label {
  letter-spacing: 0.05em;
  font-size: 0.9rem;
  position: absolute;
  top: 0px;
  left: 5px;
  color: white;
  cursor: text;
}

.field input + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate(0, 1rem) scale(1);
  pointer-events: none;
  border: none !important;
  outline: none !important;
  -webkit-transform: translate(0, 1rem) scale(1);
  -moz-transform: translate(0, 1rem) scale(1);
  -ms-transform: translate(0, 1rem) scale(1);
  -o-transform: translate(0, 1rem) scale(1);
}

.field input {
  text-decoration: none;
}

.field input:focus + label {
  cursor: text;
  transform: translate(0, 0) scale(0.8);
  transform-origin: left bottom;
  color: #666;
  border: none !important;
  outline: none !important;
  -webkit-transform: translate(0, 0) scale(0.8);
  -moz-transform: translate(0, 0) scale(0.8);
  -ms-transform: translate(0, 0) scale(0.8);
  -o-transform: translate(0, 0) scale(0.8);
}

.show-err {
  opacity: 1 !important;
  display: block !important;
}

.hide-err {
  opacity: 0 !important;
  display: none;
}

.pinErr,
.underlineEmail {
  display: none;
  opacity: 0;
  font-size: 1.1rem;
  position: absolute;
  z-index: 999;
  color: #9f2510;
  margin-top: -4px;
  pointer-events: none;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
}

.underlineEmail,
.pinErr {
  top: 70px;
}

.show {
  font-family: Poppins-Regular;
  font-size: 15px;
  color: #999999;
  line-height: 1.2;
  content: attr(data-placeholder);
  display: block;
  width: 100%;
  position: absolute;
  top: 16px;
  left: 0px;
  padding-left: 5px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.btns {
  position: relative;
  display: flex;
  width: 350px;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  font-size: 1rem;
}

.login-btn {
  z-index: 3;
  display: block;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
}

.register-btn {
  z-index: 3;
  margin: 0 auto;
  display: block;
  font-family: "Open Sans", sans-serif;
  color: #3f3e3e;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  cursor: hand;
  text-decoration: none;
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
}

.slide-btn {
  position: absolute;
  left: -10px;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  background-color: #61aac3;
  background-image: linear-gradient(90deg, #61aac3, #016e8d);
  box-shadow: 3px 3px 13px -5px rgba(0, 0, 0, 0.71);
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ok {
  display: block;
  width: 160px;
  height: 40px;
  margin: 0 auto;
  background-color: #61aac3;
  border-radius: 5px;
  background-image: -webkit-linear-gradient(0deg, #61aac3, #016e8d);
  box-shadow: 3px 3px 13px -5px rgba(0, 0, 0, 0.71);
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  cursor: hand;
  text-decoration: none;
  line-height: 40px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.slide-right {
  transform: translateX(160px);
  width: 180px;
  -webkit-transform: translateX(160px);
  -moz-transform: translateX(160px);
  -ms-transform: translateX(160px);
  -o-transform: translateX(160px);
}

.slide-left {
  transform: translateX(0px);
  width: 160px;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
}

.color-white {
  color: #fff;
}

.color-grey {
  color: #7d7d7d;
}

/*------------------------------------------------------------------
[ Responsive ]*/
@media (min-width: 100px) {
  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.visibleNone {
  display: none;
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
  .logo {
    display: block;
    margin: -50px auto;
    height: auto;
    width: 50%;
  }
  .wrap-login {
    display: flex;
    align-self: center;
    align-content: center;
    margin: 0px 10px;
    width: 90%;
  }
  .field {
    width: 100%;
  }
  .ok {
    display: block;
    width: 160px;
    height: 35px;
    font-size: 1rem;
    line-height: 30px;
  }
  .login-form h1 {
    margin: 10px auto;
    text-align: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  .btns {
    position: relative;
    display: flex;
    width: 300px;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
  }
  .slide-btn {
    position: absolute;
    left: 0px;
    width: 120px;
    height: 30px;
    font-size: 1em;
  }
  .slide-right {
    transform: translateX(130px);
    width: 150px;
    -webkit-transform: translateX(130px);
    -moz-transform: translateX(130px);
    -ms-transform: translateX(130px);
    -o-transform: translateX(130px);
  }
}/*# sourceMappingURL=LoginRegister.css.map */