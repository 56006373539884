.stopka {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0px 20px;
}

.line {
	display: block;
	width: 100%;
	border-bottom: 2px solid #adadad;
	margin: 0.1rem;
	padding: 0;
}

.stopkaDiv {
	display: grid;
	grid-template-columns: auto auto auto;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
}
.stopkaDiv p {
	font-size: 0.8rem;
	justify-self: center;
}
.stopkaLogo {
	width: 45px;
	perspective: 10/10;
	justify-self: left;
}
.logOut {
	justify-self: end;
	display: flex;
	font-size: 1.3rem;
	font-weight: bolder;
	gap: 2px;
	border: 1px solid lightgray;
	padding: 2px 20px;
	border-radius: 10px;
	cursor: pointer;
	align-items: center;
}
.logOut svg {
	align-self: center;
}
