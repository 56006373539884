.spinner {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: lightgrey;
  opacity: 0.6;
  text-align: center;
  line-height: 100vh;
  font-size: 16px;
  font-weight: bolder;
}/*# sourceMappingURL=spiner.css.map */