.singleList {
	display: flex;
	background-color: #5bb9e1;
	border: 1px solid #0fa0df;
	border-radius: 10px;
	padding: 10px 20px;
	font-size: 1rem;
	gap: 20px;
	align-items: center;
	margin: 10px 0;
	justify-content: space-between;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	-ms-border-radius: 10px;
	-o-border-radius: 10px;
	cursor: pointer;
}

.singleList a {
	display: flex;
	flex: 1;
	font-size: 1.5rem;
	color: black;
	text-decoration: none;
	text-transform: lowercase;
}
.trashIcon {
	font-size: 1.5rem;
	font-weight: bolder;
	text-transform: lowercase;
}
.singleList a p::first-letter {
	text-transform: uppercase !important;
}
@media screen and (max-width: 800px) {
	.singleList {
		display: flex;
		padding: 2px 10px;
		font-size: 1rem;
		gap: 20px;
		align-items: center;
		margin: 10px 0;
		justify-content: space-between;
	}
	.singleList a p,
	.trashIcon {
		font-size: 1.2rem;
	}
}
