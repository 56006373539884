* {
	margin: 0;
	padding: 0;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	box-sizing: border-box;
}
:root {
	font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
}

body {
	background-color: #29a6dc;
	width: 100vw;
	min-height: 100vh;
	position: relative;
	overflow: hidden;
}

