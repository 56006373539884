.containerHome {
  display: block;
  background-color: #29a6dc;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.error {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  color: white;
}

.formNewKategory {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin: 0 auto;
  flex: 1;
  gap: 15px;
}

.formNewKategory button {
  padding: 5px 35px;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: bolder;
  background-color: transparent;
}

.formNewKategory input {
  background-color: transparent;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.formNewKategory input::-moz-placeholder {
  color: black;
  opacity: 0.5;
}

.formNewKategory input::placeholder {
  color: black;
  opacity: 0.5;
}

.allList {
  display: flex;
}

.userList {
  display: block;
  margin: 10px auto;
  width: 95%;
}

@media screen and (max-width: 800px) {
  .containerHome {
    width: 100%;
    margin: 0 auto;
    border: none;
  }
  .formNewKategory {
    display: flex;
    flex-direction: column;
  }
  .formNewKategory input,
  .formNewKategory button {
    background-color: transparent;
  }
  .formNewKategory button {
    padding: 5px 35px;
    border-radius: 10px;
    font-size: 1rem;
    font-weight: bold;
  }
}/*# sourceMappingURL=Home.css.map */